// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';


Vue.config.productionTip = false;
Vue.config.silent = true;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAxios, axios);


Vue.use(auth, {
  plugins: {
    http: axios,
    router: router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'type',
    notFoundRedirect: { name: 'Home' },
    tokenDefaultName: 'access_token',
    tokenStore: ['localStorage'],
    rolesVar: 'role',
    registerData: {
      url: '/api/v1/register',
      method: 'POST',
      redirect: '/login'
    },
    loginData: {
      url: '/api/v1/login',
      method: 'POST',
      fetchUser: true
    },
    logoutData: {
      url: '/api/v1/logout',
      method: 'POST',
      redirect: '/',
      makeRequest: true
    },
    fetchData: {
      url: '/api/v1/user',
      method: 'GET',
      enabled: true
    },
    refreshData: {
      //todo: need fix refresh
      // url: 'refresh',
      // method: 'GET',
      // enabled: true,
      // interval: 60*60*24,
      enabled: false,
    },
    parseUserData: (res) => res,
  },
});


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
