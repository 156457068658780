import apiPaymentDetails from '@/api/webmaster/payment_details'

const state = () => ({
  'paymentDetails': {},
  'paymentSystems': [],
})

const getters = {}

const SET_WEBMASTER_PAYMENT_DETAILS = 'SET_WEBMASTER_PAYMENT_DETAILS';
const SET_WEBMASTER_PAYMENT_SYSTEMS = 'SET_WEBMASTER_PAYMENT_SYSTEMS';

const mutations = {
  [SET_WEBMASTER_PAYMENT_DETAILS](state, data) { state.paymentDetails = data },
  [SET_WEBMASTER_PAYMENT_SYSTEMS](state, data) { state.paymentSystems = data },
}

const actions = {
  getPaymentDetails(context) {
    return apiPaymentDetails.get().then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_WEBMASTER_PAYMENT_DETAILS', response.data.paymentDetails)
      context.commit('SET_WEBMASTER_PAYMENT_SYSTEMS', response.data.paymentSystems)
    })
  },
  storePaymentDetails(context, data) {
    return apiPaymentDetails.store(data);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
