import apiTransactions from '@/api/webmaster/transactions'

const state = () => ({
  transactions: [],
})

const getters = {
  getTransactions: state => state.transactions,
}

const SET_WEBMASTER_TRANSACTIONS = 'SET_WEBMASTER_TRANSACTIONS';

const mutations = {
  [SET_WEBMASTER_TRANSACTIONS](state, transactions) { state.transactions = transactions },
}

const actions = {
  getTransactions(context, params) {
    return apiTransactions.get(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_WEBMASTER_TRANSACTIONS', response.data.transactions)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
