import apiLinks from '@/api/webmaster/links'

const state = () => ({
  links: [],
})

const getters = {
  getLinks: state => state.links,
}

const SET_WEBMASTER_LINKS = 'SET_WEBMASTER_LINKS';

const mutations = {
  [SET_WEBMASTER_LINKS](state, links) { state.links = links },
}

const actions = {
  getLinks(context, params) {
    return apiLinks.get(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_WEBMASTER_LINKS', response.data.links)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
