import Vue from 'vue';

export default {
  get() {
    return Vue.axios
      .get("/api/v1/webmaster/settings")
      .catch(error => console.error(error));
  },
  getTdsDomain() {
    return Vue.axios
      .get("/api/v1/webmaster/tds-domains")
      .catch(error => console.error(error));
  }
}
