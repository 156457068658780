import apiSettings from '@/api/webmaster/settings'

const state = () => ({
  sites: [],
  programs: [],
  tdsDomain: '',
  bonusTypes: []
})

const getters = {
  getSiteList: state => {
    return Object.values(state.sites).map((item) => {
      return { text: item.name, value: item.id };
    });
  },
  getProgramList: state => {
    return Object.values(state.programs).map((item) => {
      return { text: item.title, value: item.id };
    });
  },
}

const SET_WEBMASTER_SITES = 'SET_WEBMASTER_SITES';
const SET_WEBMASTER_PROGRAMS = 'SET_WEBMASTER_PROGRAMS';
const SET_WEBMASTER_BONUS_TYPES = 'SET_WEBMASTER_BONUS_TYPES';
const SET_TDS_DOMAIN = 'SET_TDS_DOMAIN';

const mutations = {
  [SET_WEBMASTER_SITES](state, sites) { state.sites = sites },
  [SET_WEBMASTER_PROGRAMS](state, programs) { state.programs = programs },
  [SET_WEBMASTER_BONUS_TYPES](state, bonusTypes) { state.bonusTypes = bonusTypes },
  [SET_TDS_DOMAIN](state, domain) { state.tdsDomain = domain },
}

const actions = {
  getSettings(context) {
    return apiSettings.get().then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_WEBMASTER_SITES', response.data.sites)
      context.commit('SET_WEBMASTER_PROGRAMS', response.data.programs)
      context.commit('SET_WEBMASTER_BONUS_TYPES', response.data.bonusTypes)
    })
  },
  getTdsDomain(context) {
    return apiSettings.getTdsDomain().then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_TDS_DOMAIN', response.data.domain)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
