import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global'
import rating from './modules/rating'

import authModule from './modules/auth'
import webmasterSettings from './modules/webmaster/settings'
import webmasterDashboard from './modules/webmaster/dashboard'
import webmasterStats from './modules/webmaster/stats'
import webmasterLinks from './modules/webmaster/links'
import webmasterTransactions from './modules/webmaster/transactions'
import webmasterPaymentDetails from './modules/webmaster/payment_details'
import webmasterProfile from './modules/webmaster/profile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    rating,

    authModule,
    webmasterSettings,
    webmasterDashboard,
    webmasterStats,
    webmasterLinks,
    webmasterTransactions,
    webmasterPaymentDetails,
    webmasterProfile
  },
})
