import Vue from 'vue'
import Router from 'vue-router'

const IndexPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Index')
const ErrorPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Error')
const HomePage = import(/* webpackChunkName: "landing" */ '@/views/pages/Home')
const LoginPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Login')
const LogoutPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Logout')
const RegisterPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Register')
const VerifyPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Verify')
const ForgotPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Forgot')
const ResetPage = import(/* webpackChunkName: "landing" */ '@/views/pages/Reset')

const DashboardIndexPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Index')
const DashboardPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard')
const DashboarStatsdPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/pages/Stats')
const DashboardLinksPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/pages/Links')
const DashboardPromocodesPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/pages/Promocodes')
const DashboardTransactionsPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/pages/Transactions')
const DashboardPaymentDetailsPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/pages/PaymentDetails')
const DashboardProfilePage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/pages/UserProfile')
const DashboardSupportPage = import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/pages/Support')


const routes = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => IndexPage,
      meta: {
        auth: undefined,
      },
      children: [
        {
          name: 'Home',
          path: '/',
          component: () => HomePage,
        },
        {
          name: 'Login',
          path: 'login',
          component: () => LoginPage
        },
        {
          name: 'Logout',
          path: 'logout',
          component: () => LogoutPage,
        },
        {
          name: 'Register',
          path: 'register',
          component: () => RegisterPage,
        },
        {
          name: 'Verify',
          path: 'verify',
          component: () => VerifyPage,
        },
        {
          name: 'Forgot',
          path: 'forgot',
          component: () => ForgotPage,
        },
        {
          name: 'Reset',
          path: 'reset',
          component: () => ResetPage,
        },
      ],
    },

    {
      path: '/webmaster',
      component: () => DashboardIndexPage,
      meta: {
        auth: true,
      },
      children: [
        {
          name: 'Dashboard',
          path: 'dashboard',
          component: () => DashboardPage,
        },
        {
          name: 'Stats',
          path: 'stats',
          component: () => DashboarStatsdPage,
        },
        {
          name: 'Links',
          path: 'links',
          component: () => DashboardLinksPage,
        },
        {
          name: 'Promocodes',
          path: 'promocodes',
          component: () => DashboardPromocodesPage,
        },
        {
          name: 'Transactions',
          path: 'transactions',
          component: () => DashboardTransactionsPage,
        },
        {
          name: 'PaymentDetails',
          path: 'payment_details',
          component: () => DashboardPaymentDetailsPage,
        },
        {
          name: 'Profile',
          path: 'profile',
          component: () => DashboardProfilePage,
        },
        {
          name: 'Support',
          path: 'support',
          component: () => DashboardSupportPage,
        },
      ]
    },

    {
      path: '*',
      component: () => IndexPage,
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => ErrorPage,
        },
      ],
    },
  ],
}

Vue.use(Router)
export default new Router(routes)
