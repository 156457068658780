import apiProfile from '@/api/webmaster/profile'

const state = () => ({
  'profile': {},
})

const getters = {}

const SET_WEBMASTER_PROFILE = 'SET_WEBMASTER_PROFILE';

const mutations = {
  [SET_WEBMASTER_PROFILE](state, data) { state.profile = data },
}

const actions = {
  getDashboard(context, params) {
    return apiPaymentDetails.get(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_WEBMASTER_PROFILE', response.data.profile)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
