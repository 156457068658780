import apiAuth from '@/api/auth'

const state = () => ({
  register: {},
  verify: {},
  forgot: {},
  reset: {},
})

const getters = {}

const SET_REGISTER = 'SET_REGISTER';
const SET_VERIFY = 'SET_VERIFY';
const SET_FORGOT = 'SET_FORGOT';
const SET_RESET = 'SET_RESET';

const mutations = {
  [SET_REGISTER](state, data) { state.register = data },
  [SET_VERIFY](state, data) { state.verify = data },
  [SET_FORGOT](state, data) { state.forgot = data },
  [SET_RESET](state, data) { state.reset = data },
}

const actions = {
  register(context, params) {
    return apiAuth.register(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_REGISTER', response.data)
    })
  },
  verify(context, params) {
    return apiAuth.verify(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_VERIFY', response.data)
    })
  },
  forgot(context, data) {
    return apiAuth.forgot(data).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_FORGOT', response.data)
    })
  },
  reset(context, data) {
    return apiAuth.reset(data).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_RESET', response.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
