import apiStats from '@/api/webmaster/stats'

const state = () => ({
  stats: [],
})

const getters = {
  getStats: state => state.stats,
}

const SET_WEBMASTER_STATS = 'SET_WEBMASTER_STATS';

const mutations = {
  [SET_WEBMASTER_STATS](state, stats) { state.stats = stats },
}

const actions = {
  getStats(context, params) {
    return apiStats.get(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_WEBMASTER_STATS', response.data.stats)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
