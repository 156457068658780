import Vue from 'vue';

export default {
  get() {
    return Vue.axios
      .get("/api/v1/webmaster/payment_details")
      .catch(error => console.error(error));
  },
  store(data) {
    return Vue.axios
      .post('/api/v1/webmaster/payment_details', data)
      .catch(error => console.error(error));
  },
}
