import apiDashboard from '@/api/webmaster/dashboard'

const state = () => ({
  'baseCurrency': '',
  'balance': 0,
  'totalCredits': 0,
  'dailyRegistrations': 0,
  'rating': 0,
  'news': [],
})

const getters = {}

const SET_WEBMASTER_BASE_CURRENCY = 'SET_WEBMASTER_BASE_CURRENCY';
const SET_WEBMASTER_BALANCE = 'SET_WEBMASTER_BALANCE';
const SET_WEBMASTER_TOTAL_CREDITS = 'SET_WEBMASTER_TOTAL_CREDITS';
const SET_WEBMASTER_DAILY_REGISTRATIONS = 'SET_WEBMASTER_DAILY_REGISTRATIONS';
const SET_WEBMASTER_RATING = 'SET_WEBMASTER_RATING';
const SET_WEBMASTER_NEWS = 'SET_WEBMASTER_NEWS';

const mutations = {
  [SET_WEBMASTER_BASE_CURRENCY](state, data) { state.baseCurrency = data },
  [SET_WEBMASTER_BALANCE](state, data) { state.balance = data },
  [SET_WEBMASTER_TOTAL_CREDITS](state, data) { state.totalCredits = data },
  [SET_WEBMASTER_DAILY_REGISTRATIONS](state, data) { state.dailyRegistrations = data },
  [SET_WEBMASTER_RATING](state, data) { state.rating = data },
  [SET_WEBMASTER_NEWS](state, data) { state.news = data },
}

const actions = {
  getDashboard(context, params) {
    return apiDashboard.get(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_WEBMASTER_BASE_CURRENCY', response.data.baseCurrency)
      context.commit('SET_WEBMASTER_BALANCE', response.data.balance)
      context.commit('SET_WEBMASTER_TOTAL_CREDITS', response.data.credits)
      context.commit('SET_WEBMASTER_DAILY_REGISTRATIONS', response.data.registrations)
      context.commit('SET_WEBMASTER_RATING', response.data.rating)
      context.commit('SET_WEBMASTER_NEWS', response.data.news)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
