import api from '@/api/rating'

const state = () => ({
  rating: [],
})

const getters = {
  get: state => state.rating,
}

const SET_RATING = 'SET_RATING';

const mutations = {
  [SET_RATING](state, rating) { state.rating = rating },
}

const actions = {
  getRating(context, params) {
    return api.get(params).then(response => {
      if (typeof response === 'undefined') {
        return;
      }
      context.commit('SET_RATING', response.data.rating)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
