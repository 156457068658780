import Vue from 'vue';

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

export default {
  register(data) {
    return Vue.axios.post("/api/v1/register", data, headers)
  },

  forgot(data) {
    return Vue.axios.post("/api/v1/password/email", data, headers)
  },

  reset(data) {
    return Vue.axios.post("/api/v1/password/reset", data, headers)
  },

  verify(data) {
    return Vue.axios.post("/api/v1/email/verify/", data, headers)
  },
}
